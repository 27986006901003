/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable func-style */
import { all, takeLatest } from 'redux-saga/effects';
import { ApiService } from '../../../core/services/api.service';
import { LOGIN, RESET_PW } from './types';

import {
  setAccessToken,
  setRefreshToken,
  setUser
} from '../../../core/services/auth.service';
const api = new ApiService();

type LoginResponse = {
  accessToken: string;
  refreshToken: string;
  roleName: string;
  organizationId: string;
  email: string;
  userId: string;
  username: string;
  avatar?: string;
};

function* login({ payload, resolve, reject }: any): Generator {
  try {
    const response = yield api.doPost(['/login'], payload);
    const data = response as LoginResponse;
    setAccessToken(data.accessToken);
    setRefreshToken(data.refreshToken);
    const user = {
      userId: data.userId,
      username: data.username,
      email: data.email,
      organizationId: data.organizationId,
      roleName: data.roleName,
      avatar: data.avatar
    };
    setUser(user);
    resolve(data);
  } catch (err) {
    reject(err);
  }
}

function* resetPassword({ payload, resolve, reject }: any): Generator {
  try {
    const response = yield api.doPost(['/reset-password'], payload);
    resolve(response);
  } catch (err) {
    reject(err);
  }
}

// Main saga
export default function* authSaga() {
  yield all([takeLatest(LOGIN, login)]);
  yield all([takeLatest(RESET_PW, resetPassword)]);
}
