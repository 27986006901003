/* eslint-disable func-style */
import { all, put, takeLatest } from 'redux-saga/effects';
import { ApiService } from '../../../core/services/api.service';
import { showToast } from '../toast/actions';
import { POST_TERM_OF_USE_SETTINGS_REQUEST } from './types';
const api = new ApiService();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* postTermOfUseSettings({ payload, resolve }: any): Generator {
  try {
    const response = yield api.doPost(['settings'], payload);
    yield put(showToast(payload.msg));
    resolve(response);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
}

// Main saga
export default function* authSaga() {
  yield all([
    takeLatest(POST_TERM_OF_USE_SETTINGS_REQUEST, postTermOfUseSettings)
  ]);
}
