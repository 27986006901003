export const PRODUCTS_PREVIEW = 'PRODUCTS_PREVIEW';
export const getProductPreview = () =>
  JSON.parse(localStorage.getItem(PRODUCTS_PREVIEW));
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setProductsPreview = (data: any) => {
  localStorage.setItem(PRODUCTS_PREVIEW, JSON.stringify(data));
};
export const removeProductsPreview = () => {
  localStorage.removeItem(PRODUCTS_PREVIEW);
};
