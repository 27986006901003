/* eslint-disable @typescript-eslint/no-explicit-any */
import { HIDE_TOAST, SHOW_TOAST } from './types';

export const showToast = (payload: any) => ({
  type: SHOW_TOAST,
  payload
});

export const hideToast = (payload: any) => ({
  type: HIDE_TOAST,
  payload
});
