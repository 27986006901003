import { combineReducers } from 'redux';
import toastReducer from '../ducks/toast/reducers';
import notFoundReducer from '../ducks/notfound/reducers';
import organizationReducer from '../ducks/organizations/reducers';
import projectReducer from '../ducks/project/reducers';
import modalReducer from '../ducks/modal/reducers';
import formChangeReducer from './form-change/reducers';

const rootReducers = combineReducers({
  notFoundReducer,
  toastReducer,
  projectReducer,
  organizationReducer,
  modalReducer,
  formChangeReducer
});

export default rootReducers;

export type RootState = ReturnType<typeof rootReducers>;
