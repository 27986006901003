/* eslint-disable func-style */
import { all, fork } from 'redux-saga/effects';

import authSaga from './auth/sagas';
import organizationSaga from './organizations/sagas';
import policySaga from './policy/sagas';

// We `fork()` these tasks so they execute in the background.
export function* rootSagas() {
  yield all([
    fork(authSaga),
    fork(policySaga),
    fork(organizationSaga)
    // `fork()` any other store sagas down here...
  ]);
}
