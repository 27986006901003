import React, { useEffect } from 'react';
import './stylesheet/styles.scss';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes';
import ShowModal from 'src/shared/components/Modal';
import { RootState } from './stores/ducks/reducers';
import { getRefreshToken } from './core/services/auth.service';
import { useSelector } from 'react-redux';
import MasterStore, { useEmitter } from 'react-retix';
import {
  accessDeniedModeName,
  maintenanceModeName
} from './core/services/api.service';

new MasterStore({});

const App = () => {
  const formChangeState = useSelector(
    (state: RootState) => state.formChangeReducer
  );
  const alertUser = (e) => {
    if (getRefreshToken()) {
      e.preventDefault();
      e.returnValue = '';
    }
  };

  useEffect(() => {
    if (formChangeState.hasChanged) {
      window.addEventListener('beforeunload', alertUser);
    }
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, [formChangeState.hasChanged]);

  useEffect(() => {
    useEmitter(false, maintenanceModeName);
    useEmitter(false, accessDeniedModeName);
  }, []);

  return (
    <BrowserRouter>
      <ShowModal />
      <Routes />
    </BrowserRouter>
  );
};

export default App;
