import { Button } from '@shopify/polaris';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PATH_ENUMS } from './core/enums/roleEnum';
import { getUser } from './core/services/auth.service';
import { removeSelectedProject } from './core/services/project.service';

const PageAccessDenied = () => {
  const { roleName } = getUser();
  const { t } = useTranslation('translation');

  const handleBackToHome = () => {
    removeSelectedProject();
    window.location.href = `/${
      PATH_ENUMS[roleName as keyof typeof PATH_ENUMS]
    }`;
  };
  return (
    <div className="access-denied-page">
      <h1 className="title">403 ACCESS DENIED</h1>
      <p className="content">
        You don't have permission to access requested page.
      </p>
      <Button primary onClick={handleBackToHome}>
        {t('common.backToHome')}
      </Button>
    </div>
  );
};

export default PageAccessDenied;
