/* eslint-disable @typescript-eslint/no-empty-function */
import React, { Suspense, lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import OperatorRoute from './OperatorRoute';
import PerformerRoute from './PerformerRoute';
import PageNotFound from '../NotFound';
import AccessDenied from '../AccessDenied';
import { PATH_ENUMS } from '../core/enums/roleEnum';
import { useSubscriber } from 'react-retix';
import {
  maintenanceModeName,
  accessDeniedModeName
} from 'src/core/services/api.service';

const OperatorRoutes = lazy(() => import('../pages/Operator/OperatorRoutes'));
const PerformerRoutes = lazy(
  () => import('../pages/Performer/PerformerRoutes')
);
const Maintenance = lazy(() => import('src/shared/components/Maintenance'));

const Routes = () => {
  const accessDeniedMode: boolean = useSubscriber(accessDeniedModeName);
  const maintananceMode: boolean = useSubscriber(maintenanceModeName);

  return (
    <Suspense fallback={null}>
      <Switch>
        {accessDeniedMode && <Route path="*" component={AccessDenied} />}
        <OperatorRoute
          path={`/${PATH_ENUMS.operator}`}
          component={OperatorRoutes}
        />
        {maintananceMode && <Route path="*" component={Maintenance} />}
        <PerformerRoute
          path={`/${PATH_ENUMS.performer}`}
          component={PerformerRoutes}
        />
        <Route path="/maintenance" component={Maintenance} />
        <Route path="*" component={PageNotFound} />
      </Switch>
    </Suspense>
  );
};

export default Routes;
