import { IPaymentMethods } from 'src/stores/ducks/organizations/reducers';
import { PAYPAY_STATUS_ENUM } from './organization';

export type PaymentMethodsResponse = {
  nameKana: string;
  firstNameRepresentative: string;
  lastNameRepresentative: string;
  firstNameRepresentativeKana: string;
  lastNameRepresentativeKana: string;
  corporateNumber: string;
  birthDate?: string;
  merchandiseSale: string;
  isConfirmed?: boolean;
  paypayStatus?: PAYPAY_STATUS_ENUM;
};
export class PaymentMethodsInitData {
  data: IPaymentMethods;
  constructor(paymentMethodsData: IPaymentMethods, isSplitDate = false) {
    this.data = {
      nameKana: paymentMethodsData?.nameKana || '',
      firstNameRepresentative:
        paymentMethodsData?.firstNameRepresentative || '',
      lastNameRepresentative: paymentMethodsData?.lastNameRepresentative || '',
      firstNameRepresentativeKana:
        paymentMethodsData?.firstNameRepresentativeKana || '',
      lastNameRepresentativeKana:
        paymentMethodsData?.lastNameRepresentativeKana || '',
      corporateNumber: paymentMethodsData?.corporateNumber || '',
      birthDate: paymentMethodsData?.birthDate || '',
      merchandiseSale: paymentMethodsData?.merchandiseSale || '',
      isConfirmed: paymentMethodsData?.isConfirmed || false,
      paypayStatus:
        paymentMethodsData?.paypayStatus || PAYPAY_STATUS_ENUM.NORMAL
    };
    if (isSplitDate) {
      this.data = {
        ...this.data,
        year: paymentMethodsData?.birthDate
          ? +paymentMethodsData?.birthDate.split('-')[0]
          : '',
        month: paymentMethodsData?.birthDate
          ? +paymentMethodsData?.birthDate.split('-')[1]
          : '',
        day: paymentMethodsData?.birthDate
          ? +paymentMethodsData?.birthDate.split('-')[2]
          : ''
      };
    }
  }
}
