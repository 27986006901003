// eslint-disable-next-line camelcase
import jwtDecode from 'jwt-decode';
import { setCookie, getCookie, deleteCookie } from '../helpers/cookieHandlers';
import { removeSelectedTab } from './order.service';
import { removeProductsPreview } from './product-preview.service';
import { removeSelectedProject } from './project.service';
import { removeSuppressed } from './suppression.service';

const ACCESS_TOKEN = 'ACCESS_TOKEN';
const FRESH_TOKEN = 'FRESH_TOKEN';
const USER = 'USER';

type ACCESS_TOKEN_TYPE = {
  user: {
    userId: string;
    email: string;
    roleName: string;
  };
  iat: number;
  exp: number;
};

type REFRESH_TOKEN_TYPE = {
  user: {
    userId: string;
  };
  iat: number;
  exp: number;
};

export const setAccessToken = (token: string) => {
  setCookie(ACCESS_TOKEN, token, (jwtDecode(token) as ACCESS_TOKEN_TYPE).exp);
};

export const getAccessToken = () => getCookie(ACCESS_TOKEN);

export const removeAccessToken = () => {
  deleteCookie(ACCESS_TOKEN);
};

export const setRefreshToken = (token: string) => {
  setCookie(FRESH_TOKEN, token, (jwtDecode(token) as REFRESH_TOKEN_TYPE).exp);
};

export const getRefreshToken = () => getCookie(FRESH_TOKEN);

export const removeRefreshToken = () => {
  deleteCookie(FRESH_TOKEN);
};
type User = {
  userId: string;
  username: string;
  email: string;
  organizationId: string;
  roleName: string;
  avatar?: string;
};

const initialUser: User = {
  userId: undefined,
  username: undefined,
  email: undefined,
  organizationId: undefined,
  roleName: undefined,
  avatar: undefined
};

export const getUser = () =>
  (JSON.parse(localStorage.getItem(USER)) as User) || initialUser;

export const setUser = (user: User) => {
  localStorage.setItem(USER, JSON.stringify(user));
};

export const removeUser = () => {
  localStorage.removeItem(USER);
};

export const logout = () => {
  removeRefreshToken();
  removeAccessToken();
  removeUser();
  removeSelectedProject();
  removeSelectedTab();
  removeProductsPreview();
  removeSuppressed();
};
