/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
import { BankInfoType } from 'src/core/types/BankInfoType';
import { PaymentMethodsInitData, PaymentMethodsResponse } from 'src/shared/services/paymentMethods';
import { createReducer } from '../../../core/services/reducer-factory';
import { UPDATE_ORGANIZATION, SET_ORGANIZATION } from './types';

export enum OrganizationStatus {
  NEW = 'new',
  ALL = 'all',
  EDITING = 'editing',
  BEFORE_CONFIRM = 'beforeConfirm',
  AFTER_CONFIRM = 'afterConfirm'
}

export interface IOrganization {
  companyName: string;
  isApproveTermOfUse: boolean;
  orgId: string;
  performer: string;
  representative: string;
  bankInfo?: BankInfoType;
  specialLaws?: ISpecialLaws;
  initSpecialLaws?: ISpecialLaws;
  paymentMethods?: IPaymentMethods;
  isFetching: boolean;
}

type AttachmentsResponse = {
  fileName: string;
  url: string;
};

export interface ISpecialLaws {
  category: number;
  corporate: string;
  pic: string;
  postalCode: string;
  location: string;
  phone: string;
  mail: string;
  homepageUrl: string;
  paymentMethod: string;
  paymentPeriod: string;
  deliveryPeriod: string;
  vendor?: string;
  priceAndShippingFee: string;
  returnCancel: string;
  attachments: AttachmentsResponse[];
  lawsStatus: OrganizationStatus;
  // kanaCompanyName: string;
  // kanaRepresentative: string;
  // dateOfBirth: string;
}
export type IPaymentMethods = PaymentMethodsResponse & {
  year?: number | string;
  month?: number | string;
  day?: number | string;
};

const initialState: IOrganization = {
  companyName: '',
  isApproveTermOfUse: undefined,
  orgId: '',
  performer: '',
  representative: '',
  isFetching: true
};

const setOrganization = (state: any, payload: any) => {
  return {
    ...state,
    ...payload,
    paymentMethods: new PaymentMethodsInitData(payload?.paymentMethods).data,
    initSpecialLaws: payload?.specialLaws,
    isFetching: false
  };
};

const updateOrganization = (state: any, payload: any) => {
  const { option, data } = payload;

  return {
    ...state,
    [option]: { ...state[option], ...data },
    isApproveTermOfUse: data.isApproveTermOfUse || state.isApproveTermOfUse
  };
};

const strategies = {
  [SET_ORGANIZATION]: setOrganization,
  [UPDATE_ORGANIZATION]: updateOrganization,
  __default__: (state) => state
};

const organizationReducer = createReducer(strategies, initialState);

export default organizationReducer;
