import React, { lazy, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useLocation, useHistory } from 'react-router-dom';
import { hideNotFound } from 'src/stores/ducks/notfound/action';
import { RootState } from 'src/stores/ducks/reducers';
import { PATH_ENUMS } from '../core/enums/roleEnum';
import { getUser, getRefreshToken } from '../core/services/auth.service';
import PageNotFound from '../NotFound';
import AuthRoute from './AuthRoute';
const AuthRoutes = lazy(() => import('../pages/Auth/AuthRoutes'));
interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: any;
  path: string;
}
const OperatorRoute: React.FC<Props> = ({ component: Component, path }) => {
  const refreshToken = getRefreshToken();
  const { roleName } = getUser();
  const location = useLocation();
  const history = useHistory();
  const loginPath = `${path}/login`;
  const dispatch = useDispatch();

  const { t } = useTranslation('translation');

  useEffect(() => {
    if (!refreshToken && location.pathname !== loginPath) {
      history.push(loginPath);
    }
    if (location) {
      dispatch(hideNotFound());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const notFoundReducer = useSelector(
    (state: RootState) => state.notFoundReducer
  );

  useEffect(() => {
    document.title = t('titleWeb', { name: t('common.operator') });
  }, []);

  return refreshToken ? (
    <>
      {path.substring(1) === PATH_ENUMS[roleName as keyof typeof PATH_ENUMS] &&
      !notFoundReducer ? (
        <Route
          path={path}
          render={(matchProps) => <Component {...matchProps} />}
        />
      ) : (
        <PageNotFound />
      )}
    </>
  ) : (
    <AuthRoute path="/:rolePath" component={AuthRoutes} />
  );
};

export default OperatorRoute;
