import React, { FC } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { getUser, getRefreshToken } from '../core/services/auth.service';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: any;
  path: string;
}

const AuthRoute: FC<Props> = (props) => {
  const { component: Component, path } = props;
  const refreshToken = getRefreshToken();
  const { roleName } = getUser();

  return (
    <Route
      path={path}
      render={(props) => {
        if (refreshToken) {
          return <Redirect to={`${roleName}`} />;
        }
        return <Component {...props} />;
      }}
    />
  );
};

export default AuthRoute;
