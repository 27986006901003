/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable func-style */
import { all, put, takeLatest } from 'redux-saga/effects';
import { ApiService } from '../../../core/services/api.service';
import { GET_ORGANIZATION, SET_ORGANIZATION } from './types';
const api = ApiService.getInstance();

// function* updateOrganization({ payload, resolve }: any): Generator {
//   try {
//     const { option } = payload;
//     const response = yield api.doPut(['myorganization'], payload);
//     yield put({
//       type: PUT_ORGANIZATION,
//       payload: { option: option, data: response }
//     });
//     resolve(true);
//   } catch (err) {
//     // eslint-disable-next-line no-console
//     console.log(err);
//   }
// }

function* getOrganization(): Generator {
  try {
    const response = yield api.doGet(['myorganization']);
    yield put({
      type: SET_ORGANIZATION,
      payload: response
    });
  } catch (err) {
    yield put({
      type: SET_ORGANIZATION
    });
  }
}

// Main saga
export default function* organizationSaga() {
  yield all([
    // takeLatest(UPDATE_ORGANIZATION, updateOrganization),
    takeLatest(GET_ORGANIZATION, getOrganization)
  ]);
}
