/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
import { createReducer } from '../../../core/services/reducer-factory';
import * as types from './types';

const initialState = {
  hasChanged: false,
  modalContent: {
    title: '',
    content: '',
    isHtml: false,
    agreeLabel: '',
    cancelLabel: '',
  }
};

const setFormChange = (state: any, payload: any) => {
  return { ...state, ...payload };
};

const resetFormChanged = () => {
  return initialState;
};

const strategies = {
  [types.SET_FORM_CHANGE]: setFormChange,
  [types.RESET_FORM_CHANGE]: resetFormChanged,
  __default__: (state) => state,
};

const formChangeReducer = createReducer(strategies, initialState);

export default formChangeReducer;
