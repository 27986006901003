import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  getAccessToken,
  getRefreshToken,
  logout,
  setAccessToken,
  setRefreshToken
} from './auth.service';
import { useEmitter } from 'react-retix';
import { ToastService, TOAST_ENUM } from 'src/shared/services/toast';
import {
  FORBIDDEN_ACCESS,
  SERVICE_UNAVAILABLE
} from 'src/shared/constant/errors';

const createURL = (uri: string[]) => {
  let paramsUrl = '';
  if (typeof uri[uri.length - 1] !== 'string') {
    paramsUrl = uri.pop();
    let url = uri.join('/');
    Object.keys(paramsUrl).forEach((x) => {
      url = url.replace(`:${x}`, paramsUrl[x]);
    });
    return url;
  }
  return uri.join('/');
};

export const updateStateStorage = (
  state: boolean,
  nameMode: string,
  remove: boolean
) => {
  useEmitter(state, nameMode);
  remove
    ? sessionStorage.removeItem(nameMode)
    : sessionStorage.setItem(nameMode, 'ON');
};

const API_DOMAIN = process.env.REACT_APP_API_ENDPOINT;
const REFRESH_TOKEN_ENDPOINT = '/refresh-token';

export const maintenanceModeName = 'maintenanceMode';
export const accessDeniedModeName = 'accessDeniedMode';

export class ApiService {
  axiosInstance;
  refreshingToken;

  private static classInstance?: ApiService;
  constructor(isUseAuthorization = true) {
    this.axiosInstance = axios.create({
      baseURL: API_DOMAIN,
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (isUseAuthorization) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.axiosInstance.interceptors.request.use((_config: any) => {
        _config.headers.Authorization = `Bearer ${getAccessToken()}`;
        return _config;
      });
    }
    this._setInterceptors();
    this.refreshingToken = null;
  }

  static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new ApiService();
    }

    return this.classInstance;
  }

  doGet(uri: string[], moreConfigs = {}, isFullUrl = false) {
    return new Promise((resolve, reject) => {
      const request = this.axiosInstance.get(
        isFullUrl ? uri : createURL(uri),
        moreConfigs
      );
      this._handleRespond(request, resolve, reject);
    });
  }

  doPost(uri: string[], data = {}, moreConfigs = {}) {
    return new Promise((resolve, reject) => {
      const request = this.axiosInstance.post(
        createURL(uri),
        data,
        moreConfigs
      );
      this._handleRespond(request, resolve, reject);
    });
  }

  doPut(uri: string[], data = {}, moreConfigs = {}) {
    return new Promise((resolve, reject) => {
      const request = this.axiosInstance.put(createURL(uri), data, moreConfigs);
      this._handleRespond(request, resolve, reject);
    });
  }

  doDelete(uri: string[], moreConfigs = {}) {
    return new Promise((resolve, reject) => {
      const request = this.axiosInstance.delete(createURL(uri), moreConfigs);
      this._handleRespond(request, resolve, reject);
    });
  }

  doMethod(method: string, uri: string[], data = {}, moreConfigs = {}) {
    return new Promise((resolve, reject) => {
      const request = this.axiosInstance({
        method,
        url: createURL(uri),
        data,
        ...moreConfigs
      });
      this._handleRespond(request, resolve, reject);
    });
  }

  doMultipleAPI(
    method = 'GET',
    apiRequests: string[],
    data = [],
    moreConfigs = []
  ) {
    const apiReqs = apiRequests.map((url: string, index) => {
      return this.axiosInstance({
        method,
        url,
        data: data[index],
        headers: moreConfigs[index]
      });
    });
    return new Promise((resolve, reject) => {
      axios
        .all(apiReqs)
        .then((resp: AxiosResponse[]) => {
          resolve(resp.map((v: AxiosResponse) => v.data));
        })
        .catch((err: AxiosError) => reject(err));
    });
  }

  async doSequentlyAPIs(
    method = 'doPost',
    requestData: { path: string; body?: unknown }[],
    moreConfigs = []
  ) {
    const dataRes = [];
    const error = [];
    for (const item of requestData) {
      await this[method]([item.path], item.body || {}, moreConfigs)
        .then((res: AxiosResponse[]) => {
          dataRes.push(res);
        })
        .catch((err: AxiosError) => error.push(err));
    }
    return { dataRes, error };
  }

  private _setInterceptors() {
    this.axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => {
        if (sessionStorage.getItem(maintenanceModeName) === 'ON') {
          updateStateStorage(false, maintenanceModeName, true);
        }
        if (sessionStorage.getItem(accessDeniedModeName) === 'ON') {
          updateStateStorage(false, accessDeniedModeName, true);
        }
        return response;
      },
      (error: AxiosError) => this._handleError(error)
    );
  }

  private _handleRespond(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    request: any,
    resolve: (val: unknown) => void,
    reject: (val: unknown) => void
  ) {
    return request
      .then((resp: AxiosResponse) => {
        resolve(resp.data);
      })
      .catch((err: unknown) => {
        reject(err);
      });
  }

  private async _handleError(error: AxiosError) {
    if (error.isAxiosError && error.response?.status === 401) {
      if (
        getRefreshToken() &&
        error.response?.config.url !== REFRESH_TOKEN_ENDPOINT
      ) {
        try {
          const originalRequest = error.config;
          this.refreshingToken = this.refreshingToken
            ? this.refreshingToken
            : this.doPost([REFRESH_TOKEN_ENDPOINT], {
                refreshToken: getRefreshToken()
              });
          const response = await this.refreshingToken;
          this.refreshingToken = null;
          const data = response as {
            accessToken: string;
            refreshToken: string;
          };
          setAccessToken(data.accessToken);
          setRefreshToken(data.refreshToken);
          return this.axiosInstance(originalRequest);
        } catch (err) {
          return Promise.reject(err);
        }
      } else {
        logout();
        if (error.response?.config.url === REFRESH_TOKEN_ENDPOINT) {
          location.reload();
        }
      }
    }
    if (error.isAxiosError && error.response?.status === FORBIDDEN_ACCESS) {
      updateStateStorage(true, accessDeniedModeName, false);
    }
    if (error.isAxiosError && error.response?.status === SERVICE_UNAVAILABLE) {
      updateStateStorage(true, maintenanceModeName, false);
    }
    // Detect refresh Token
    // Make error model before promise
    let errorData = {};
    const toastMsg = 'エラーレスポンス\n時間をおいて再度お試しください。';
    if (error.isAxiosError && error.response) {
      // toastMsg = error.message;
      errorData = error.response.data;
    }
    if (
      getRefreshToken() &&
      error.isAxiosError &&
      error.response?.status !== SERVICE_UNAVAILABLE
    ) {
      const data = new ToastService({
        msg: toastMsg,
        type: TOAST_ENUM.error
      });
      useEmitter(data, 'error');
    }

    return Promise.reject(errorData);
  }
}
