import { createReducer } from '../../../core/services/reducer-factory';
import { SHOW_MODAL, HIDE_MODAL, UPDATE_MODAL } from './types';

const initialState = {
  active: false,
  title: '',
  content: '',
  isHtml: false,
  isCustomTitle: false,
  primaryAction: {
    content: '',
    onAction: null,
    destructive: false
  },
  secondaryActions: null,
  footer: ''
};

const showModal = (state, payload) => {
  payload['active'] = true;
  return { ...state, ...payload };
};

const hideModal = () => {
  return initialState;
};

const updateModal = (state, payload) => {
  return { ...state, ...payload };
};

const strategies = {
  [SHOW_MODAL]: showModal,
  [HIDE_MODAL]: hideModal,
  [UPDATE_MODAL]: updateModal,
  __default__: (state) => state
};

const modalReducer = createReducer(strategies, initialState);

export default modalReducer;
