import React from 'react';
import DOMPurify from 'dompurify';

type Props = {
  html: string;
  isDefaultStyle?: boolean;
  isNotShowScroll?: boolean;
  isRichtext?: boolean;
};
const InsertHtml: React.FC<Props> = ({
  html,
  isDefaultStyle = false,
  isNotShowScroll,
  isRichtext = true
}) => {
  return (
    <>
      <div
        id={`${!isDefaultStyle ? 'inner-html' : ''}`}
        className={`custom-inner-html ${
          isNotShowScroll ? 'overflow-visible' : ''
        } ${!isRichtext ? 'break-spaces' : ''}`}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(html, { ADD_ATTR: ['target'] })
        }}
      ></div>
    </>
  );
};

export default React.memo(InsertHtml);
