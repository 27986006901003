export enum ROLE_ENUMS {
  operator = 'operator',
  owner = 'performer',
  superOperator = 'superOperator'
}

export enum PATH_ENUMS {
  operator = 'operator',
  performer = 'owner',
  superOperator = 'operator'
}
