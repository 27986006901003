import React from 'react';
import InsertHtml from '../InsertHtml';
import { Modal, TextContainer } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/stores/ducks/reducers';
import { hideModal } from 'src/stores/ducks/modal/actions';

const ShowModal = () => {
  const { t } = useTranslation('translation');
  const modalReducer = useSelector((state: RootState) => state.modalReducer);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hideModal());
  };
  const {
    title,
    content,
    active,
    isHtml,
    footer,
    primaryAction,
    isCustomTitle,
    secondaryActions = {
      content: t('close'),
      onAction: handleClose
    }
  } = modalReducer;

  return (
    <div>
      <Modal
        noScroll={true}
        open={active}
        onClose={secondaryActions?.onAction || handleClose}
        title={
          isCustomTitle ? (
            title
          ) : (
            <InsertHtml html={title} isDefaultStyle={true} />
          )
        }
        primaryAction={primaryAction?.onAction ? primaryAction : null}
        secondaryActions={secondaryActions === null ? [] : [secondaryActions]}
        footer={footer}
      >
        {content && (
          <Modal.Section>
            <TextContainer>
              {isHtml ? (
                <InsertHtml html={content} />
              ) : (
                <div className="break-spaces overflow-break">{content}</div>
              )}
            </TextContainer>
          </Modal.Section>
        )}
      </Modal>
    </div>
  );
};

export default React.memo(ShowModal);
