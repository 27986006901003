import React, { lazy, useEffect } from 'react';
import { Route, useLocation, useHistory } from 'react-router-dom';
import { PATH_ENUMS } from '../core/enums/roleEnum';
import { getRefreshToken, getUser } from '../core/services/auth.service';
import PageNotFound from '../NotFound';
import AuthRoute from './AuthRoute';
import { removeSelectedProject } from 'src/core/services/project.service';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/stores/ducks/reducers';
import { hideNotFound } from 'src/stores/ducks/notfound/action';
import { useTranslation } from 'react-i18next';
import { removeSelectedTab } from 'src/core/services/order.service';
const AuthRoutes = lazy(() => import('../pages/Auth/AuthRoutes'));
interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: any;
  path: string;
}
export const HASH_GUIDELINE = 'HASH_GUIDELINE';
const PeformerRoute: React.FC<Props> = ({ component: Component, path }) => {
  const location = useLocation();

  const refreshToken = getRefreshToken();
  const { roleName } = getUser();

  const history = useHistory();
  const loginPath = `${path}/login`;
  const resetPWPath = `${path}/reset-password`;
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');

  useEffect(() => {
    if (
      !refreshToken &&
      location.pathname !== loginPath &&
      location.pathname !== resetPWPath
    ) {
      removeSelectedProject();
      removeSelectedTab();
      if (location.pathname === `/${PATH_ENUMS.performer}` && location.hash) {
        sessionStorage.setItem(HASH_GUIDELINE, location.hash);
      }
      history.push(loginPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const notFoundReducer = useSelector(
    (state: RootState) => state.notFoundReducer
  );

  useEffect(() => {
    document.title = t('titleWeb', { name: t('common.performer') });
  }, []);

  useEffect(() => {
    if (location && notFoundReducer) {
      dispatch(hideNotFound());
    }
  }, [location]);

  return refreshToken ? (
    <>
      {path.substring(1) === PATH_ENUMS[roleName as keyof typeof PATH_ENUMS] &&
      !notFoundReducer ? (
        <Route
          path={path}
          render={(matchProps) => <Component {...matchProps} />}
        />
      ) : (
        <PageNotFound />
      )}
    </>
  ) : (
    <AuthRoute path="/:rolePath" component={AuthRoutes} />
  );
};

export default PeformerRoute;
