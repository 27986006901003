import './index.css';
import '@shopify/polaris/build/esm/styles.css';

import { AppProvider } from '@shopify/polaris';
import jaTranslations from '@shopify/polaris/locales/ja.json';
import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';

import App from './App';
import i18n from './i18n';
import * as serviceWorker from './serviceWorker';
import { store } from './stores';

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <AppProvider i18n={jaTranslations}>
        <App />
      </AppProvider>
    </I18nextProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
