export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const RESET_PW = 'RESET_PW';
export const RESET_PW_SUCCESS = 'RESET_PW_SUCCESS';
export const RESET_PW_FAILED = 'RESET_PW_FAILED';

export type BlockAction =
  | typeof LOGIN
  | typeof LOGIN_SUCCESS
  | typeof LOGIN_FAILED
  | typeof RESET_PW
  | typeof RESET_PW_SUCCESS
  | typeof RESET_PW_FAILED;
