export enum TOAST_ENUM {
  success = 1,
  error = 2
}

export type BaseToast = {
  id: number;
  type: number;
  msg: string;
};

export class ToastService {
  id: number;
  type: number;
  msg: string;
  constructor(item: { msg: string; type?: number; id?: number }) {
    this.id = item.id || Date.now();
    this.type = item.type || TOAST_ENUM.success;
    this.msg = item.msg;
  }
}
