export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';
export const GET_ORGANIZATION = 'GET_ORGANIZATION';
export const SET_ORGANIZATION = 'SET_ORGANIZATION';
export const PUT_ORGANIZATION = 'PUT_ORGANIZATION';

export type BlockAction =
  | typeof UPDATE_ORGANIZATION
  | typeof GET_ORGANIZATION
  | typeof SET_ORGANIZATION
  | typeof PUT_ORGANIZATION;
