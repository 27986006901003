import { createReducer } from '../../../core/services/reducer-factory';
import { HIDE_NOT_FOUND, SHOW_NOT_FOUND } from './types';

const initialState = false;

const showNotFound = () => {
  return true;
};

const hideNotFound = () => {
  return false;
};

const strategies = {
  [SHOW_NOT_FOUND]: showNotFound,
  [HIDE_NOT_FOUND]: hideNotFound,
  __default__: (state) => state
};

const notFoundReducer = createReducer(strategies, initialState);

export default notFoundReducer;
