/* eslint-disable @typescript-eslint/no-explicit-any */

import { ProjectsType } from 'src/core/types/ProjectType';
import { createReducer } from '../../../core/services/reducer-factory';
import { SET_PROJECTS } from './types';

export interface IProject {
  code: string;
}

export type ProjectsState = {
  isFetching: boolean;
  data: ProjectsType[];
};

const projectsInitialState: ProjectsState = {
  isFetching: true,
  data: []
};

const setProjects = (state: any, payload: ProjectsState) => {
  return payload;
};

const strategies = {
  [SET_PROJECTS]: setProjects,
  __default__: (state) => state
};

const projectReducer = createReducer(strategies, projectsInitialState);

export default projectReducer;
