import React from 'react';

const PageNotFound = () => {
  return (
    <div className="not-found-page">
      <h1>404 NOT FOUND</h1>
    </div>
  );
};

export default PageNotFound;
