const SELECTED_PROJECT = 'SELECTED_PROJECT';

export const getSelectedProject = () =>
  sessionStorage.getItem(SELECTED_PROJECT);

export const setSelectedProject = (projectCode: string) => {
  sessionStorage.setItem(SELECTED_PROJECT, projectCode);
};

export const removeSelectedProject = () => {
  sessionStorage.removeItem(SELECTED_PROJECT);
};
