import { BaseToast, ToastService } from 'src/shared/services/toast';
import { createReducer } from '../../../core/services/reducer-factory';
import { HIDE_TOAST, SHOW_TOAST } from './types';

const initialState = [] as BaseToast[];

const showToast = (state, payload) => {
  const temp = new ToastService(
    typeof payload === 'string' ? { msg: payload } : payload
  );
  return [...state, temp];
};

const hideToast = (state: BaseToast[], payload: number) => {
  return [...state].filter((item) => item.id !== payload);
};

const strategies = {
  [SHOW_TOAST]: showToast,
  [HIDE_TOAST]: hideToast,
  __default__: (state) => state
};

const toastReducer = createReducer(strategies, initialState);

export default toastReducer;
