/* eslint-disable @typescript-eslint/no-explicit-any */
import { SHOW_MODAL, HIDE_MODAL, UPDATE_MODAL } from './types';

export const showModal = (payload: any) => ({
  type: SHOW_MODAL,
  payload
});

export const hideModal = () => ({
  type: HIDE_MODAL
});

export const updateModal = (payload: any) => ({
  type: UPDATE_MODAL,
  payload
});
